import React, { useState, FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';

import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import IndexLayout from '../components/common/Layout';
import {
  UserLookupViewerQueryQuery,
  UserPhoneLookupQueryQuery,
  UserIdLookupQueryQuery,
  UserFragment,
} from '../generated/graphql';
import { DisplayError } from '../components/DisplayError';
import { Unauthorized } from '../components/Unauthorized';
import { userFragment, User } from '../components/User';

const userLookupViewerQuery = gql`
  query userLookupViewerQuery {
    viewer {
      admin {
        id
        email
        permissions
      }
    }
  }
`;

const userPhoneLookupQuery = gql`
  query userPhoneLookupQuery(
    $countryCode: String!
    $phoneNumber: String!
    $canReadUserMessages: Boolean! = false
    $canReadUserData: Boolean! = false
    $canReadSensitiveUserData: Boolean! = false
  ) {
    admin {
      userPhoneLookup(countryCode: $countryCode, phoneNumber: $phoneNumber) {
        result {
          id
          ...user
          premium {
            isEnabled
          }
        }
      }
    }
  }
  ${userFragment}
`;

const userIdLookupQuery = gql`
  query userIdLookupQuery(
    $userId: String!
    $canReadUserMessages: Boolean! = false
    $canReadUserData: Boolean! = false
    $canReadSensitiveUserData: Boolean! = false
  ) {
    admin {
      userIdLookup(userId: $userId) {
        result {
          id
          ...user
          premium {
            isEnabled
          }
        }
      }
    }
  }
  ${userFragment}
`;

const SearchBox = styled.div`
  padding: 10px;
  background: #eee;
`;
const FormElm = styled.div`
  display: inline-block;
  margin-right: 20px;
`;
const UserResult = styled.div`
  margin-top: 16px;
`;

const UserLookupPage = () => {
  const viewerQuery = useQuery<UserLookupViewerQueryQuery>(
    userLookupViewerQuery,
  );

  const [showResults, setShowResults] = useState(false);
  const [countryCode, setCountryCode] = useState('86');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [searchBy, setSearchBy] = useState<'phone' | 'id'>('phone');
  const [id, setId] = useState<string>('');

  const onSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();
    setShowResults(true);
  };
  const handleSetSearchBy = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchBy(event.target.value as 'phone' | 'id');
  };

  const renderInner = () => {
    const { loading, error, data } = viewerQuery;
    if (!data || loading) return <p>Loading...</p>;
    if (error) return <DisplayError error={error} />;
    if (!data.viewer.admin) return <Unauthorized />;

    const resultComponent =
      searchBy === 'phone' ? (
        <PhoneResults phoneNumber={phoneNumber} countryCode={countryCode} />
      ) : (
        <IdResults userId={id}></IdResults>
      );

    return (
      <>
        <h1>User Lookup</h1>
        <p>Search for user by phone number or ID</p>

        <SearchBox>
          <FormControl style={{ marginBottom: 16, minWidth: 120 }}>
            <InputLabel id="select-search-by">Search using</InputLabel>
            <Select
              labelId="select-search-by"
              id="demo-simple-select"
              value={searchBy}
              onChange={handleSetSearchBy}
            >
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="id">ID</MenuItem>
            </Select>
          </FormControl>

          {searchBy === 'phone' ? (
            <form onSubmit={onSubmit}>
              <FormElm>
                <label>
                  Country Code
                  <input
                    type="tel"
                    pattern="\d*"
                    maxLength={4}
                    size={4}
                    value={countryCode}
                    required
                    onChange={evt => setCountryCode(evt.target.value)}
                  />
                </label>
              </FormElm>
              <FormElm>
                <label>
                  Phone number
                  <input
                    type="tel"
                    pattern="\d*"
                    value={phoneNumber}
                    required
                    onChange={evt => setPhoneNumber(evt.target.value)}
                  />
                </label>
              </FormElm>
              <FormElm>
                <input type="submit" value="Search" />
              </FormElm>
            </form>
          ) : (
            <form onSubmit={onSubmit}>
              <FormElm>
                <label>
                  ID
                  <input
                    value={id}
                    required
                    onChange={evt => setId(evt.target.value)}
                  />
                </label>
              </FormElm>
              <FormElm>
                <input type="submit" value="Search" />
              </FormElm>
            </form>
          )}
        </SearchBox>
        <div>{showResults && resultComponent}</div>
      </>
    );
  };

  return (
    <IndexLayout>
      <Page>
        <Container>{renderInner()}</Container>
      </Page>
    </IndexLayout>
  );
};

interface PhoneResultsProps {
  phoneNumber: string;
  countryCode: string;
}

const PhoneResults: FC<PhoneResultsProps> = ({ phoneNumber, countryCode }) => {
  const lookupQuery = useQuery<UserPhoneLookupQueryQuery>(
    userPhoneLookupQuery,
    {
      variables: {
        countryCode,
        phoneNumber,
      },
    },
  );
  const { loading, error, data } = lookupQuery;

  if (loading) return <p>Loading...</p>;
  if (error) return <DisplayError error={error} />;

  const result = data?.admin?.userPhoneLookup?.result;

  if (!result) return <p>No User Found :(</p>;

  return <Results {...result}></Results>;
};

interface IdResultsProps {
  userId: string;
}

const IdResults: FC<IdResultsProps> = ({ userId }) => {
  const lookupQuery = useQuery<UserIdLookupQueryQuery>(userIdLookupQuery, {
    variables: {
      userId,
    },
  });
  const { loading, error, data } = lookupQuery;

  if (loading) return <p>Loading...</p>;
  if (error) return <DisplayError error={error} />;

  const result = data?.admin?.userIdLookup?.result;

  if (!result) return <p>No User Found :(</p>;

  return <Results {...result}></Results>;
};

const Results: FC<UserFragment> = result => {
  return (
    <UserResult>
      <User id={result.id} />
    </UserResult>
  );
};

export default UserLookupPage;
